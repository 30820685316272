<template>
    <div class="capability" v-touch:swipe.top="onSwipeTop"
     v-touch:swipe.right="onSwipeRight"
     v-touch:swipe.left="gotoPage">
        <div class="title">
            <div class="t2">Capability Analysis</div>
            <p>七大能力解析</p>
        </div>
        <div class="capability-box">
            <div class="tab">
                <div class="tab-item" @click="ontab(1)">
                    <img src="../assets/img/capability/tab2-1.png"
                     alt="" v-if="index.indexOf(1)>=0">
                    <img src="../assets/img/capability/tab2.png" alt="" v-else>
                </div>
                <div class="tab-item" @click="ontab(0)">
                    <img src="../assets/img/capability/tab1-1.png"
                     alt="" v-if="index.indexOf(0)>=0">
                    <img src="../assets/img/capability/tab1.png" alt="" v-else>
                </div>
            </div>
            <div class="capability-list">
                <div class="capability-item" v-for="(item,index) in textArr"
                 :key="index">
                  <div class="capability-title">{{item.desc}}</div>
                  <img src="../assets/img/capability/direction.png"
                   alt="" ondragstart="return false;">
                  <div class="capability-content">
                      {{item.english_desc}}
                  </div>
                </div>
            </div>
        </div>
        <!-- <div class="to" @click="gotoPage">
          <div class="to-left" @click="gotoPage">
              <div class="to-t1">Capability Analysis</div>
              <div class="to-t2">七大能力解析</div>
          </div>
          <img src="../assets/img/home/right.png" alt="">
      </div> -->
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import * as d3 from 'd3';
// eslint-disable-next-line no-unused-vars
import Chart from '../utils/chart';

export default {
  data() {
    return {
      title: '',
      chart: '',
      config: {
        margins: {
          top: 80, left: 80, bottom: 50, right: 80,
        }, // 位置信息
        textColor: 'black', // 项标题
        title: '学情报告', // 标题
        radius: 150, // 大小？
        animateDuration: 500, // 动作缓冲时间
        tickNum: 5, // 雷达间隔
        axisfillColor: ['#fff', '#fff'], // 背景间隔
        linefillColor: ['rgba(255, 166, 37, 0.1)', 'rgba(237, 202, 126, 0.05)'],
        selectColor: ['#8AD4FF', '#83DAAD'], // 其他线段
        selectpointColor: ['rgba(138, 212, 255, 0.05)', 'rgba(131, 218, 173, 0.05)'], // 其他点线段
        polylineColor: ['#FFA625', '#eeca7f'], // 折线颜色
        axisStrokeColor: '#eee', // 雷达线格颜色
        pointsColor: '#FFA625', // 圆点颜色
        pointsSize: 3, // 点大小
      },
      num: 0,
      newdata: [],
      user: '',
      basicCapabilityArr: [],
      capabilityList: [],
      index: [2],
      textArr: [],
      transformData: 2,
    };
  },
  created() {
  },
  mounted() {
    const that = this;
    this.user = JSON.parse(localStorage.getItem('student'));
    that.basicCapability();
    // setTimeout(() => {
    //   that.chart.renderText();
    //   that.chart.addMouseOn();
    // }, 1500);
  },
  methods: {
    onSwipeRight() {
      this.$router.go(-1);
    },
    gotoPage() {
      this.$router.push({
        name: 'MeCapability',
      });
    },
    ontab(i) {
      const num = this.index.indexOf(i);
      if (num >= 0) {
        this.index.splice(num, 1);
      } else {
        this.index.push(i);
      }
      console.log(this.index);
      this.changPolygons();
    },
    changPolygons() {
      const that = this;
      for (let i = 0; i < that.config.selectColor.length; i += 1) {
        const polygons = d3.select(`.g-person${i + 1}`).select('.polygon');
        polygons
          .attr('opacity', that.index.indexOf(i) >= 0 ? 1 : 0)
          .attr('fill', that.config.selectpointColor[i])
          // (d, i) => chart.colors(i)
          // eslint-disable-next-line no-underscore-dangle
          .attr('stroke', that.config.selectColor[i])
          .transition()
          .duration(that.config.animateDuration);
        const person = d3.selectAll(`.point-person${i + 1}`);
        person
          .attr('fill', that.config.selectColor[i])
          .attr('opacity', that.index.indexOf(i) >= 0 ? 1 : 0);
      }
    },
    //   初始化图表
    initChart() {
      const that = this;
      const chart = new Chart();
      that.chart = chart;
      const { config } = that;
      const data = that.basicCapabilityArr;

      that.newdata = data;
      // eslint-disable-next-line no-debugger
      /* ----------------------------配置参数------------------------  */
      that.chart.margins(that.config.margins);

      /* ----------------------------尺度转换------------------------  */
      chart.scaleRadius = d3.scaleLinear()
        .domain([0, 100])
        .range([0, config.radius]);

      /* ----------------------------渲染动作说明------------------------  */
      // chart.renderAction = function () {
      //   chart.body().append('g')
      //     .attr('class', 'sss');
      // };
      /* ----------------------------渲染坐标轴------------------------  */
      chart.renderAxes = function () {
        // ----渲染背景多边形-----
        const points = that.getPolygonPoints(data.length, config.radius, config.tickNum);
        const axes = chart.body().append('g')
          .attr('class', 'axes')
          .attr('transform', `translate(${chart.getBodyWidth() / 2},${chart.getBodyHeight() / 2})`)
          .selectAll('axis')
          .data(points);

        axes.enter()
          .append('polygon')
          .attr('class', 'axis')
          .merge(axes)
          .attr('points', (d) => d)
          .attr('fill', (d, i) => (i % 2 === 0 ? config.axisfillColor[0] : config.axisfillColor[1]))
          .attr('stroke', config.axisStrokeColor);

        axes.exit()
          .remove();

        // ----渲染对角线-----
        const line = d3.line();

        const outerPoints = that.getOuterPoints(points[0]);

        const lines = d3.select('.axes')
          .selectAll('.line')
          .data(outerPoints);

        lines.enter()
          .append('path')
          .attr('class', 'line')
          .merge(lines)
          .attr('d', (d) => line([
            [0, 0],
            [d[0], d[1]],
          ]))
          .attr('stroke', config.axisStrokeColor);

        lines.exit()
          .remove();
        setTimeout(() => {
          let num = 120;
          for (let i = 0; i < points.length; i += 1) {
            num -= 20;
            let item = points[i].split(' ');
            item = item[0].split(',');
            item.push(num);
            that.initScaleNode(item);
          }
        }, 500);
      };

      /* ----------------------------渲染文本标签------------------------  */
      chart.renderText = function () {
        const texts = d3.select('.axes')
          .selectAll('.label')
          .data(data);

        texts.enter()
          .append('image')
          .attr('class', 'label')
          .merge(texts)
          .attr('xlink:href', (d, i) => `https://alicdn.joyballet.cn/studentStudy/capability/icon/${d.subject}-${i > 0 && i < 4 ? 2 : 1}.png`)
        // eslint-disable-next-line no-mixed-operators
          .attr('x', (d, i) => Math.sin(i * Math.PI * 2 / data.length) * (config.radius + 50) - 28)
        // eslint-disable-next-line no-mixed-operators
          .attr('y', (d, i) => Math.cos(i * Math.PI * 2 / data.length) * (config.radius + 50) - 28)
          .attr('index', (d, i) => i)
          .attr('subject', (d) => d.subject)
          .attr('width', 56)
          .attr('height', 56);
      };

      /* ----------------------------渲染数据多边形------------------------  */
      chart.renderPolygons = function () {
        const newData = that.handleData(data);
        const polygons = chart.body().selectAll('.polygons')
          .data(newData);

        polygons.enter()
          .append('g')
          .attr('class', (d) => `g-${d.person}`)
          .attr('transform', `translate(${chart.getBodyWidth() / 2},${chart.getBodyHeight() / 2})`)
          .append('polygon')
          .attr('class', 'polygon')
          .merge(polygons)
          .attr('fill', (d, i) => (that.index.indexOf(i) >= 0 ? config.linefillColor[0] : config.linefillColor[1]))
        // eslint-disable-next-line no-underscore-dangle
          .attr('stroke', (d, i) => (that.index.indexOf(i) >= 0 ? config.polylineColor[0] : config.polylineColor[1]))
          .attr('stroke-width', '2')
          .attr('points', (d) => {
            const miniPolygon = [];
            d.forEach(() => {
              miniPolygon.push('0,0');
            });
            return miniPolygon.join(' ');
          })
          .transition()
          .duration(config.animateDuration)
          .attr('points', that.generatePolygons)
          .attr('opacity', (d, i) => (i < 2 ? 0 : 1));

        polygons.exit()
          .remove();
      };

      /* ----------------------------绑定鼠标交互事件------------------------  */
      chart.addMouseOn = function () {
        d3.selectAll('.label')
          .on('click', (node, i) => {
            // node.path[0].getAttribute('index') node.path[0].getAttribute('subject')
            const index = i.person4;
            // eslint-disable-next-line eqeqeq
            if (index == '1') return;
            const { subject } = i;
            that.$router.push({
              name: 'MeCapability',
              params: {
                index,
                type: subject,
              },
            });
            // alert(index);
          });
      };

      chart.render = function () {
        chart.renderAxes();

        chart.renderText();

        // chart.renderAction();

        chart.renderPolygons();

        chart.addMouseOn();
      };

      chart.renderChart('.capability-box');
    },
    initScaleNode(item) {
      // let x=item[0];
      // eslint-disable-next-line radix
      const y = parseInt(item[1]);
      this.chart.body().append('text')
        .attr('class', 'scale')
        .text(item[2])
        .attr('z-index', 200)
        .attr('font-size', 12)
        .attr('font-weight', 'bold')
        .attr('width', 56)
        .style('fill', '#ccc')
        .attr('transform', item[2] === 100 ? `translate(${this.chart.getBodyWidth() / 2 - 10},${this.chart.getBodyHeight() / 2 + y})` : `translate(${this.chart.getBodyWidth() / 2 - 7},${this.chart.getBodyHeight() / 2 + y})`);
    },
    basicCapability() {
      const that = this;
      this.$server.fetch('/api/study/report/basicCapability', {
        student_id: this.user.student_id,
      }).then((res) => {
        if (res.code === 200) {
          const arr = [];
          const arr2 = [];
          const { data } = res;
          let kk = 0;
          // eslint-disable-next-line no-restricted-syntax
          for (const key in data) {
            if (key) {
              const obj = {};
              obj.subject = key;
              const obj2 = {
                person1: res.data[key].score.excellent,
                person2: res.data[key].score.pass,
                person3: res.data[key].score.standard,
                person4: kk,
              };
              Object.assign(obj, obj2);
              arr.push(obj);
              arr2.push(res.data[key]);
              kk += 1;
            }
          }

          that.capabilityList = arr2;
          that.basicCapabilityArr = arr;
          that.textArr = that.capabilityList.slice(1, 4).reverse();
          that.initChart();
        }
      });
    },
    //   z转动
    onSwipeTop() {
      // eslint-disable-next-line no-debugger
      const { chart } = this;
      this.num += 1;
      if (this.transformData === 1) {
        this.transformData = 7;
      } else {
        this.transformData -= 1;
      }
      console.log(this.transformData);
      const textArr = [];
      for (let i = 0; i < 3; i += 1) {
        const num = (this.transformData - i + 8) % 7;
        console.log(num);
        textArr.push(this.capabilityList[num]);
      }
      /*
        nodeindex=10
        当前下表
      */
      // for (let i = 0; i < this.capabilityList.length; i += 1) {
      //   if ((i + nodeindex) % 7 >= 0 && (i + nodeindex) % 7 < 4) {
      //     if ((nodeindex % 7) >= i) {
      //       textArr.unshift(this.capabilityList[i]);
      //     } else {
      //       textArr.push(this.capabilityList[i]);
      //     }
      //   }
      // }
      console.log(textArr);
      console.log(this.capabilityList);
      this.textArr = textArr;
      d3.select('.axes')
        .transition()
        .duration(1000)
        .attr('transform', `translate(${chart.getBodyWidth() / 2},${chart.getBodyHeight() / 2}), rotate(${-51.4285 * this.num})`);

      d3.select('.g-person1')
        .transition()
        .duration(1000)
        .attr('transform', `translate(${chart.getBodyWidth() / 2},${chart.getBodyHeight() / 2}), rotate(${-51.4285 * this.num})`);

      d3.select('.g-person2')
        .transition()
        .duration(1000)
        .attr('transform', `translate(${chart.getBodyWidth() / 2},${chart.getBodyHeight() / 2}), rotate(${-51.4285 * this.num})`);
      d3.select('.g-person3')
        .transition()
        .duration(1000)
        .attr('transform', `translate(${chart.getBodyWidth() / 2},${chart.getBodyHeight() / 2}), rotate(${-51.4285 * this.num})`);
      // debugger
      // const arr = d3.selectAll('.label');
      const texts = d3.select('.axes')
        .selectAll('.label')
        .data(this.newdata);

      texts.enter()
        .append('image')
        .attr('class', 'label')
        .merge(texts)
        .attr('xlink:href', (d, i) => {
          const num = (i + this.num) % 7 > 0 && (i + this.num) % 7 < 4 ? 2 : 1;
          return `https://alicdn.joyballet.cn/studentStudy/capability/icon/${d.subject}-${num}.png`;
        })
        .transition()
        .duration(500)
      // eslint-disable-next-line no-mixed-operators
        .attr('x', (d, i) => Math.sin((i + this.num >= 6 ? ((i + this.num) % 7) : (i + this.num)) * Math.PI * 2 / this.newdata.length) * (this.config.radius + 50) - 28)
      // eslint-disable-next-line no-mixed-operators
        .attr('y', (d, i) => Math.cos((i + this.num >= 6 ? ((i + this.num) % 7) : (i + this.num)) * Math.PI * 2 / this.newdata.length) * (this.config.radius + 50) - 28)
        .attr('transform', `rotate(${51.4285 * this.num})`)
        .attr('width', 56)
        .attr('height', 56);
    },
    // 计算多边形的顶点并生成顶点圆圈
    generatePolygons(d, index) {
      const that = this;
      const points = [];
      // eslint-disable-next-line no-mixed-operators
      const anglePiece = Math.PI * 2 / d.length;

      d.forEach((item, i) => {
        const x = Math.sin(i * anglePiece) * that.chart.scaleRadius(item[1]);
        const y = Math.cos(i * anglePiece) * that.chart.scaleRadius(item[1]);

        // 添加交点圆圈
        d3.select(`.g-${d.person}`)
          .append('circle')
          .attr('class', `point-${d.person}`)
          .attr('fill', that.config.pointsColor)
          .attr('stroke', '#fff')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', that.config.pointsSize)
          .attr('opacity', 0.6)
          .transition()
          .duration(that.config.animateDuration)
          .attr('opacity', (index < 2 ? 0 : 1))
          .attr('cx', x)
          .attr('cy', y);

        points.push(`${x},${y}`);
      });

      return points.join(' ');
    },
    // 处理数据，转化数据结构，方便渲染
    handleData(data) {
      const newData = [];
      Object.keys(data[0]).forEach((key) => {
        if (key !== 'subject') {
          const item = [];
          item.person = key;
          newData.push(item);
        }
      });

      data.forEach((d) => {
        newData.forEach((item, i) => {
          item.push([d.subject, d[`person${i + 1}`]]);
        });
      });
      return newData;
    },
    computeTextAnchor(data, i) {
      if (data.length < 3) return;

      // eslint-disable-next-line no-mixed-operators
      const angle = i * 360 / data.length;

      if (angle === 0 || Math.abs(angle - 180) < 0.01) {
        // eslint-disable-next-line consistent-return
        return 'middle';
      } if (angle > 180) {
        // eslint-disable-next-line consistent-return
        return 'end';
      }
      // eslint-disable-next-line consistent-return
      return 'start';
    },
    // 生成背景多边形的顶点
    getPolygonPoints(vertexNum, outerRadius, tickNum) {
      const points = [];
      let polygon;

      if (vertexNum < 3) return points;

      // eslint-disable-next-line no-mixed-operators
      const anglePiece = Math.PI * 2 / vertexNum;
      const radiusReduce = outerRadius / tickNum;

      for (let r = outerRadius; r > 0; r -= radiusReduce) {
        polygon = [];

        for (let i = 0; i < vertexNum; i += 1) {
          polygon.push(
            `${Math.sin(i * anglePiece) * r},${Math.cos(i * anglePiece) * r}`,
          );
        }

        points.push(polygon.join(' '));
      }

      return points;
    },

    // 得到最外层多边形的顶点
    getOuterPoints(outerPoints) {
      const points = outerPoints.split(' ').map((d) => d.split(','));
      return points;
    },
  },
};
</script>
<style lang="less">
    .capability-box{
         width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .box{
            margin-left: -2.1rem;
        }
        .tab{
            position: fixed;
            bottom: 6%;
            left: 45%;
            // margin-bottom: -5.8rem;
            display: flex;
            margin-left: -1.6rem;
            .tab-item{
                margin-right: 0.5rem;
            }
            img{
                width:0.67rem ;
                height: 0.33rem;
            }
        }
        .capability-list{
            // position: absolute;
            // left: 50%;
            // margin-left: 1rem;
            // margin-bottom: -0.3rem;
            .capability-item{
                position: absolute;
                width: 2.88rem;
                margin-bottom: 0.65rem;
                height: 0;
                .capability-name{
                    flex-shrink: 0;
                    width: 0.05rem;
                    height: 0.05rem;
                    border-radius: 50%;
                    border: 1px solid #EDCA7E;
                    margin-right: 0.1rem;
                }
                .capability-title{
                    font-size: 14px;
                    color: #EDCA7E;
                    line-height: 20px;
                    padding-left: 0.23rem;
                    position: absolute;
                    width: 100%;
                    transform-origin: bottom content;
                    bottom: 0;
                }
                .capability-content{
                    font-size: 10px;
                    line-height: 0.15rem;
                    padding-left: 0.24rem;
                    margin-top: -0.18rem;
                }
            }
            .capability-item:nth-child(1){
              position: absolute;
              left: 50%;
              top: 50%;
              margin-left: 0rem;
              margin-top: -2.2rem;
            }
            .capability-item:nth-child(2){
              position: absolute;
              left: 50%;
              top: 50%;
              margin-left: 1.05rem;
              margin-top: -0.86rem;
            }
            .capability-item:nth-child(3){
              position: absolute;
              left: 50%;
              top: 50%;
              margin-left: 0.75rem;
              margin-top: 0.8rem;
            }
        }
    }
    .g-person4{
      opacity: 0;
    }
    .capability{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        .title{
            position: fixed;
            top: 0.65rem;
            left: 0.31rem;
            text-align: left;
            padding-top: 0.38rem;
            background: url('../assets/img/capability/title.png') no-repeat;
            background-size: 1.39rem 0.52rem;
            .t2{
                font-size: 0.31rem;
                font-weight: bold;
            }
            p{
                font-size: 0.16rem;
                opacity: 0.6;
            }
        }
        .container {
            margin: 30px auto;
            width: 600px;
            height: 300px;
        }
    }
</style>
